import { useState } from 'react';
import Footer from '../../navigation/Footer';
import HeroSectionAllPage from '../section/HeroSectionAllPage';
import Image4 from '../../../assets/images/Google_Fonts_logo2-1.webp'
import Image5 from '../../../assets/images/helpcenter-salient-1.webp'
import BeforeAfterSection from '../../pages/section/BeforeAfterSection';
import PPCimg from '../../../assets/video/PPC-Home-GIf.gif';
import WebDevelopmentimg from '../../../assets/video/Development-Home-Gif.gif';
import Analyticsimg from '../../../assets/video/Analytics-Home-Gif.gif';
import ClientLogo from '../../pages/section/ClientLogo';

const SearchEngineOptimization = () => {
    const [isMuted, setIsMuted] = useState(true);
    return (
        <>
            <HeroSectionAllPage />
            <div className='w-full'>
                <div className='container mx-auto px-[1rem] lg:px-[4rem] overflow-hidden mt-28'>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {/* PPC Management */}
                        <div className="bg-gray-100 p-8 rounded-3xl flex flex-col justify-between">
                            <div>
                                <h3 className="leading-tight mb-4 text-[25px]">PPC Management</h3>
                                <p className='text-[16px]'>
                                    Wasting money on ads that don’t convert? Our targeted PPC strategies focus on reaching
                                    high-intent customers, optimizing every campaign with in-depth keyword research and A/B testing.
                                    It’s not just about clicks — it’s about driving quality traffic that leads to sustainable growth.
                                </p>
                            </div>
                            <img src={PPCimg} alt="PPC Management" />
                        </div>

                        {/* Website Development */}
                        <div className="bg-blue-500 p-8 rounded-3xl text-white flex flex-col justify-between">
                            <div>
                                <h3 className="leading-tight mb-4 text-[25px]">Website Development</h3>
                                <p className='text-[16px]'>
                                    Struggling with high bounce rates? We design websites that do more than look good.
                                    By understanding your brand and customer journey, we create fast, intuitive sites that
                                    engage visitors and convert them into loyal customers.
                                </p>
                            </div>
                            <img src={WebDevelopmentimg} alt="Website Development" />
                        </div>

                        {/* Analytics & Insights */}
                        <div className="bg-yellow-500 p-8 rounded-3xl text-white flex flex-col justify-between">
                            <div>
                                <h3 className="leading-tight mb-4 text-[25px]">Analytics & Insights</h3>
                                <p className='text-[16px]'>
                                    Making decisions without data is risky. Our analytics go beyond basic metrics to
                                    uncover actionable insights into your ad performance and website behavior.
                                    This empowers you to refine strategies, optimize budgets, and drive better results.
                                </p>
                            </div>
                            <img src={Analyticsimg} alt="Analytics & Insights" />
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-full'>
                <div className='container mx-auto px-[1rem] lg:px-[4rem] overflow-hidden pb-[6rem] lg:pb-[10rem]'>
                    <div className='bg-gray-100 rounded-3xl mt-8 p-6 lg:p-0 pb-0'>
                        <div className='grid grid-cols-1 md:grid-cols-5 gap-4'>
                            <div className='col-span-1'></div>
                            <div className='col-span-3 text-center lg:mt-16 mt-8'>
                                <h3 className='mb-2'>Performance You Can Measure</h3>
                                <p>Discover how optimized strategies drove better clicks, conversions, and ROI.</p>
                            </div>
                            <div className='col-span-1'></div>
                        </div>
                        <div className='py-8'>
                            <BeforeAfterSection />
                        </div>
                    </div>

                    <div className='grid grid-cols-1 md:grid-cols-5 gap-8 mt-8'>
                        <div className='col-span-2 flex flex-col'>
                            <div className='bg-yellow-400 p-8 rounded-3xl flex-1'>
                                <h3 className='leading-tight mb-2 text-[25px]'>Delivering Value, Driving Growth</h3>
                                <p>Here’s what our partners say about working with us!</p>
                                <video
                                    autoPlay
                                    loop
                                    controls
                                    muted={isMuted}
                                    className="rounded-xl mt-4"
                                >
                                    <source src={require("../../../assets/video/Home-Videos.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                        <div className='col-span-3 flex flex-col'>
                            <div className='flex bg-blue-500 p-8 rounded-3xl text-white gap-x-10 flex-1'>
                                <div>
                                    <h3 className='mb-2 text-[25px]'>Insights & Industry Stats</h3>
                                    <p>Choose from over 1,400 Google fonts with powerful responsive typography settings.</p>
                                </div>
                                <div>
                                    <img src={Image4} alt="" />
                                </div>
                            </div>
                            <div className='bg-gray-100 px-0 py-8 rounded-3xl gap-x-10 mt-8'>
                                <h3 className='px-8 text-[25px] '>Proudly Partnered with These Brands</h3>
                                <ClientLogo />
                            </div>
                        </div>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-8 mt-8'>
                        <div className='bg-gray-100 rounded-3xl'>
                            <div className='p-8'>
                                <h3>Success Probability Calculator </h3>
                                <p><span className='text-[20px] font-semibold'>Purpose:</span> Engage visitors with a personalized assessment tool to estimate how successful a PPC campaign or website redesign could be for their business.
                                </p>
                            </div>
                            <img src={Image5} alt="" className='rounded-bl-3xl rounded-br-3xl' />
                        </div>
                        <div className='bg-card-home p-8 rounded-3xl text-white'>
                            <h3>What’s Your Marketing Score?</h3>
                            <p><span className='text-[20px] font-semibold'>Purpose: </span>Provide an engaging way for visitors to assess their current marketing performance and identify areas of improvement.</p>
                        </div>
                    </div>

                    <div className="mt-16 md:mt-28 flex justify-center mb-16">
                        <h2>How It Works?</h2>
                    </div>
                    <div className="grid grid-cols-3 relative">
                        {/* Step 1 */}
                        <div className="text-center">
                            <img src={PPCimg} alt="" className="mx-auto" />
                        </div>
                        <div className="flex flex-col items-center relative">
                            <span className="rounded-full bg-orange-400 text-white w-10 h-10 flex items-center justify-center">01</span>
                            {/* Vertical Line */}
                            <div className="absolute top-10 bottom-0 w-[1px] bg-gray-300"></div>
                        </div>
                        <div className='block m-auto'>
                            <h3 className="font-bold">Select your style:</h3>
                            <p>
                                Pick your theme or style preference from our diverse range of templates.
                                Whether it’s professional, conversational, persuasive, or something
                                entirely unique, choose the tone that best suits your needs.
                            </p>
                        </div>
                    </div>
                    <div className="grid grid-cols-3 relative ">
                        {/* Step 2 */}
                        <div className='block m-auto'>
                            <h3 className="font-bold">Customize it:</h3>
                            <p>
                                Tailor the template to fit your specific requirements. Add your personal
                                touch to ensure it’s perfect for you.
                            </p>
                        </div>
                        <div className="flex flex-col items-center relative">
                            <span className="rounded-full bg-orange-400 text-white w-10 h-10 flex items-center justify-center">02</span>
                            {/* Vertical Line */}
                            <div className="absolute top-10 bottom-0 w-[1px] bg-gray-300"></div>
                        </div>
                        <div className="text-center">
                            <img src={PPCimg} alt="" className="mx-auto" />
                        </div>
                    </div>
                    <div className="grid grid-cols-3 relative">
                        {/* Step 3 */}
                        <div className="text-center">
                            <img src={PPCimg} alt="" className="mx-auto" />
                        </div>
                        <div className="flex flex-col items-center relative">
                            <span className="rounded-full bg-orange-400 text-white w-10 h-10 flex items-center justify-center">03</span>
                            {/* Vertical Line */}
                            <div className="absolute top-10 bottom-0 w-[1px] bg-gray-300"></div>
                        </div>
                        <div className='block m-auto'>
                            <h3 className="font-bold">Select your style:</h3>
                            <p>
                                Pick your theme or style preference from our diverse range of templates.
                                Whether it’s professional, conversational, persuasive, or something
                                entirely unique, choose the tone that best suits your needs.
                            </p>
                        </div>
                    </div>

                </div>
            </div>


            <Footer />
        </>
    );
};

export default SearchEngineOptimization;
