import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import FunFactImg from '../../../assets/images/FunFact.webp';
import DidYouKnowImg from '../../../assets/images/DidYouKnow.webp';
import Insights from '../../../assets/images/Insights.webp';

const FunFact = () => {
  const facts = [
    {
      heading: "Fun Facts",
      content: [
        "First Impressions Count: Users form an opinion about your website in 0.05 seconds. Make that half-second work with stunning visuals and fast loading!",
        "Gamify and Convert: Adding gamification (like quizzes or points) to your site increases engagement by 47%. Fun equals loyalty!"
      ],
      image: FunFactImg
    },
    {
      heading: "Did You Know?",
      content: [
        "Voice Search Wave: By 2025, 50% of searches will be voice-driven. Optimize for conversational keywords to capture this trend.",
        "Social Commerce Boom: 80% of Instagram users make purchase decisions based on what they see in the app. E-commerce is social!",
        "AI-Driven Recommendations: 35% of Amazon's revenue comes from AI-powered product recommendations. Personalization drives growth!"
      ],
      image: DidYouKnowImg
    },
    {
      heading: "Insights",
      content: [
        "PPC Domination: Businesses using targeted PPC campaigns grow their leads by 54% faster compared to those that don’t. Precision pays off!",
        "The AI Factor: 61% of marketers now use AI to optimize PPC campaigns. Embrace automation to stay ahead of the curve."
      ],
      image: Insights
    }
  ];

  const [currentFactIndex, setCurrentFactIndex] = useState(0);
  const [currentContentIndex, setCurrentContentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (isHovered) return;

    const interval = setInterval(() => {
      setCurrentContentIndex((prevContentIndex) => {
        const nextContentIndex = prevContentIndex + 1;
        if (nextContentIndex >= facts[currentFactIndex].content.length) {
          setTimeout(() => {
            setCurrentFactIndex((prevFactIndex) => (prevFactIndex + 1) % facts.length);
          }, 2000);
          return 0;
        }
        return nextContentIndex;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, [currentContentIndex, currentFactIndex, isHovered]);

  return (
    <div className="text-white">
      <h3 className="mb-2 text-[25px]">Insights & Industry Stats</h3>
      <div className="grid grid-cols-3 items-center">
        {/* Text Section */}
        <div className="col-span-2">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentFactIndex}
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 100 }}
              transition={{ duration: 0.8 }}
            >
              <motion.h4 className="mb-2">
                {facts[currentFactIndex].heading}
              </motion.h4>
              <div className="relative overflow-hidden h-[100px]">
                <motion.p
                  key={`${currentFactIndex}-${currentContentIndex}`}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.5 }}
                  className="absolute"
                >
                  {facts[currentFactIndex].content[currentContentIndex]}
                </motion.p>
              </div>
            </motion.div>
          </AnimatePresence>
        </div>

        {/* Image Section */}
        <div className="overflow-hidden relative h-[250px] w-full flex items-center justify-center">
          <AnimatePresence mode="wait">
            <motion.img
              key={currentFactIndex}
              src={facts[currentFactIndex].image}
              alt={facts[currentFactIndex].heading}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.8 }}
              className="absolute h-full w-auto object-contain"
            />
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default FunFact;
