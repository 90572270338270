import { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { fetchData } from '../../services/apiService';

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    const getFaqs = async () => {
      try {
        const data = await fetchData('faqs');
        setFaqs(data);
      } catch (error) {
        console.error('Failed to fetch FAQs:', error);
      }
    };
    getFaqs();
  }, []);

  const handleToggle = (index) => setActiveIndex(index === activeIndex ? null : index);

  return (
    <div className="max-w-3xl mx-auto p-4 py-28">
      <div className='text-center'>
        <h2 className="text-[2rem] lg:text-[3rem]">Frequently Asked Questions</h2>
      </div>
      {faqs.map((faq, index) => (
        <div key={index} className={`border-b ${index === activeIndex ? 'shadow-lg rounded-3xl mb-4' : ''}`}>
          <div className="flex items-center justify-between p-[10px] lg:p-6 cursor-pointer" onClick={() => handleToggle(index)}>
            <h3 className="text-[20px] lg:text-[25px]">{faq.title}</h3>
            <div className={`p-[10px] w-10 h-10 flex items-center justify-center border-2 rounded-full ${index === activeIndex ? 'border-gray-400' : 'border-gray-300'}`}>
              <FontAwesomeIcon icon={activeIndex === index ? faMinus : faPlus} className="text-lg transition-transform duration-300" />
            </div>
          </div>
          <AnimatePresence>
            {activeIndex === index && (
              <motion.div className="px-6 pb-6 pt-0" initial={{ height: 0, opacity: 0 }} animate={{ height: 'auto', opacity: 1 }} exit={{ height: 0, opacity: 0 }} transition={{ duration: 0.5 }}>
                <p>{faq.content}</p>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ))}
    </div>
  );
};

export default FAQSection;
