import React, { useEffect, useState } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { fetchData, getImageUrl } from '../../../services/apiService';
import HeroSectionForm from './HeroSectionForm';

const SolutionHeroSection = () => {
  const [data, setData] = useState({});
  const { scrollY } = useScroll();

  // Set transforms based on screen size and scroll position
  const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;
  const paddingX = useTransform(scrollY, [0, 0], isMobile ? ['0rem', '4rem'] : ['0rem', '5rem']);
  const scale = useTransform(scrollY, [0, 300], isMobile ? [1, 1] : [1, 0.85]);
  const marginTop = useTransform(scrollY, [0, 300], isMobile ? ['0rem', '0rem'] : ['-8rem', '0rem']);
  const boxShadow = useTransform(scrollY, [0, 300], ['rgba(0, 0, 0, 0.3) 60px 60px 50px 0px', 'rgba(0, 0, 0, 0.3) 60px 60px 50px 0px']);

  useEffect(() => {
    const loadHeroSection = async () => {
      try {
        const [heroSectionData] = await fetchData('heroSolutionsSection');
        setData(heroSectionData || {});
      } catch (error) {
        console.error('Error fetching hero section data:', error);
      }
    };
    loadHeroSection();
  }, []);

  const { title1, title2, title3, bgimg } = data;

  return (
    <div className="bg-slate-400 bg-gradient overflow-hidden">
      <div className="text-center lg:pb-16 pb-[4rem] pt-60 max-w-[800px] mx-auto">
        {title1 && <span className="text-[1rem] border-2 border-black rounded-full px-5 py-1 font-semibold">{title1}</span>}
        {title2 && <h1 className="my-8">{title2}</h1>}
        {title3 && <p className="text-[18px]">{title3}</p>}
      </div>
      <HeroSectionForm />
      <motion.div
        style={{ scale, paddingLeft: paddingX, paddingRight: paddingX }}
        className="bg-slate-200 pt-10 pb-10 rounded-2xl"
      >
        <motion.div
          style={{ scale, marginTop, boxShadow }}
          className='rounded-2xl'
        >
          {bgimg && (
            <motion.img
              src={getImageUrl(bgimg)}
              alt="Hero Section"
              className="hero-section-shadow rounded-2xl w-full"
              style={{ marginTop }}
            />
          )}
        </motion.div>
      </motion.div>
    </div>
  );
};

export default SolutionHeroSection;
