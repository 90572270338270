import React, { useEffect } from 'react';
import { useAnimation, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import FounderImg from '../../../assets/images/Team/Founder.webp';
import CSOImg from '../../../assets/images/Team/CSO.webp';
import CMOImg from '../../../assets/images/Team/CMO.webp'

const Team = () => {
  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();

  const [ref1, inView1] = useInView({ triggerOnce: false, threshold: 0.2 });
  const [ref2, inView2] = useInView({ triggerOnce: false, threshold: 0.2 });
  const [ref3, inView3] = useInView({ triggerOnce: false, threshold: 0.2 });

  useEffect(() => {
    if (inView1) {
      controls1.start({
        y: [0, -30, 0], // Subtle movement
        rotate: [0, 5, -5, 0], // Small rotation
        transition: {
          duration: 2.5,
          ease: 'easeInOut',
          repeatType: 'mirror',
          repeat: Infinity,
        },
      });
    } else {
      controls1.stop();
    }
  }, [inView1, controls1]);

  useEffect(() => {
    if (inView2) {
      controls2.start({
        y: [0, -40, 0],
        rotate: [0, 8, -8, 0],
        transition: {
          duration: 2.0,
          ease: 'easeInOut',
          repeatType: 'mirror',
          repeat: Infinity,
        },
      });
    } else {
      controls2.stop();
    }
  }, [inView2, controls2]);

  useEffect(() => {
    if (inView3) {
      controls3.start({
        y: [0, -20, 0],
        rotate: [0, 4, -4, 0],
        transition: {
          duration: 2.8,
          ease: 'easeInOut',
          repeatType: 'mirror',
          repeat: Infinity,
        },
      });
    } else {
      controls3.stop();
    }
  }, [inView3, controls3]);

  return (
    <div className='w-full relative'>
      <div className='container mx-auto px-[1rem] lg:px-[4rem] lg:my-24 my-8 overflow-hidden'>
        <div className='grid grid-cols-1 md:grid-cols-5 gap-4'>
          <div className='col-span-1'></div>
          <div className='col-span-3 text-center'>
            <h2>Meet our team of extraordinary innovators</h2>
          </div>
          <div className='col-span-1'></div>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-16 mt-32'>
          {/* First Image and Text */}
          <div className='border border-black p-12 rounded-3xl mb-32'>
            <span className='text-[1rem] border border-black rounded-full px-5 py-1'>Founder and CEO</span>
            <h3 className='mt-6'>Abhishek Gupta</h3>
            <p className='mt-12'>
              Meet Abhishek, the driving force behind our vision. With a passion for innovation and a commitment
              to helping businesses grow, he leads our team in crafting digital solutions that truly make an impact.
              He believes in leveraging the latest technology and real-time insights to lead projects that truly
              resonate with our clients’ needs. Under his leadership, our team is committed to delivering results
              that drive growth, amplify brand presence, and make a real difference in your business journey.</p>
          </div>

          <motion.div
            ref={ref1}
            animate={controls1}
            initial={{ y: 0, rotate: 0 }}
            className='rounded-3xl mb-32'
          >
            <img src={FounderImg} alt="Jacob Smith" className='rounded-3xl'
              style={{
                width: '100%',
                height: '500px',
                objectFit: 'cover'
              }}
            />
          </motion.div>

          {/* Second Image and Text */}
          <motion.div
            ref={ref2}
            animate={controls2}
            initial={{ y: 0, rotate: 0 }}
            className='rounded-3xl mb-32'
          >
            <img src={CMOImg} alt="Jacob Smith" className='rounded-3xl'
              style={{
                width: '100%',
                height: '500px',
                objectFit: 'cover'
              }}
            />

          </motion.div>
          <div className='border border-black p-12 rounded-3xl mb-32'>
            <span className='text-[1rem] border border-black rounded-full px-5 py-1'>CMO </span>
            <h3 className='mt-6'>Arjun Dev</h3>
            <p className='mt-12'>
              As our Chief Marketing Officer, Arjun brings a vibrant energy and strategic insight to our
              marketing initiatives. With a knack for innovative solutions and a deep understanding of the
              digital landscape, he has been instrumental in driving growth for our clients. Having led multiple
              successful projects, Arjun is passionate about leveraging strategies to amplify brand visibility
              and conversions. Always focused on client success, he ensures our approach is both impactful and
              results-oriented.
            </p>
          </div>

          {/* Third Image */}
          <div className='border border-black p-12 rounded-3xl mb-16'>
            <span className='text-[1rem] border border-black rounded-full px-5 py-1'>CSO </span>
            <h3 className='mt-6'>Aviral Gupta</h3>
            <p className='mt-12'>
              Meet Aviral, our Chief Sales Consultant. With a natural flair for understanding client
              needs and a strategic mindset, Aviral drives our sales initiatives to deliver unmatched
              results. His expertise in building strong client relationships and tailored sales strategies
              has been instrumental in our company’s growth. Aviral’s dedication to ensuring client satisfaction
              sets him apart, making him an invaluable part of our journey towards your business success.
            </p>
          </div>

          <motion.div
            ref={ref3}
            animate={controls3}
            initial={{ y: 0, rotate: 0 }}
            className='rounded-3xl'
          >
            <img src={CSOImg} alt="Jacob Smith" className='rounded-3xl'
              style={{
                width: '100%',
                height: '500px',
                objectFit: 'cover'
              }}
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Team;
