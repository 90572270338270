import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Button = ({
  onClick,
  to,             // Set this prop for internal routing links
  href,           // Set this prop for external links
  label = 'Submit',
  size = 'medium', // small, medium, large
  padding = 'py-2 px-4', // Tailwind class string or { top: 8, right: 16, bottom: 8, left: 16 }
  style,
}) => {
  const [move, setMove] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const button = e.currentTarget;
    const rect = button.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;

    const moveX = ((offsetX / rect.width) - 0.5) * 8;
    const moveY = ((offsetY / rect.height) - 0.5) * 8;

    setMove({ x: moveX, y: moveY });
  };

  const handleMouseLeave = () => setMove({ x: 0, y: 0 });

  // Button size classes
  const sizeClasses = {
    small: 'text-sm',
    medium: 'text-md',
    large: 'text-lg',
  };

  // Determine padding style (Tailwind class or pixel-based)
  const paddingStyle = typeof padding === 'string'
    ? padding // Use Tailwind padding class if string
    : {
        paddingTop: `${padding.top || 0}px`,
        paddingRight: `${padding.right || 0}px`,
        paddingBottom: `${padding.bottom || 0}px`,
        paddingLeft: `${padding.left || 0}px`,
      };

  const ButtonContent = (
    <button
      className={`border-2 border-black rounded-full flex items-center gap-2 group hover:border-transparent transition-all duration-300 ${sizeClasses[size]}`}
      style={{
        transform: `translate(${move.x}px, ${move.y}px)`,
        transition: 'transform 0.1s ease-out',
        ...style,
        ...typeof paddingStyle === 'object' ? paddingStyle : {}, // Apply pixel padding if in object format
      }}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      {label}
      <span className="icon-border">
        <FontAwesomeIcon icon={faArrowRight} />
      </span>
    </button>
  );

  return to ? (
    <Link to={to} onClick={onClick}>
      {ButtonContent}
    </Link>
  ) : (
    <a href={href || '#'} onClick={onClick}>
      {ButtonContent}
    </a>
  );
};

export default Button;
