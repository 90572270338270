import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';
const ServiceNav = () => {
    const location = useLocation();

    const isActive = (path) => location.pathname === path;
    return (
        <>
            <div className='grid grid-cols-1 md:grid-cols-2 mt-32 items-center'>
                <div>
                    <h4>Discover More</h4>
                    <ul className='flex gap-6 mt-4'>
                        <li className={`border-2 px-[20px] py-[5px] rounded-3xl ${isActive('/solutions/seo') ? 'bg-black text-white' : 'border-[#4b4b4b]'}`}>
                            <Link to="/solutions/seo">SEO</Link>
                        </li>
                        <li className={`border-2 px-[20px] py-[5px] rounded-3xl ${isActive('/solutions/smm') ? 'bg-black text-white' : 'border-[#4b4b4b]'}`}>
                            <Link to="/solutions/smm">SMM</Link>
                        </li>
                        <li className={`border-2 px-[20px] py-[5px] rounded-3xl ${isActive('/solutions/sem') ? 'bg-black text-white' : 'border-[#4b4b4b]'}`}>
                            <Link to="/solutions/sem">SEM</Link>
                        </li>
                        <li className={`border-2 px-[20px] py-[5px] rounded-3xl ${isActive('/solutions/email-marketing') ? 'bg-black text-white' : 'border-[#4b4b4b]'}`}>
                            <Link to="/solutions/email-marketing">Email Marketing</Link>
                        </li>
                    </ul>
                </div>
                <div>
                    <FontAwesomeIcon icon={faArrowDown} className='float-right rotate-45 text-[7rem]' />
                </div>
            </div>

        </>
    )
}

export default ServiceNav