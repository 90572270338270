import React from 'react';
import { Link } from 'react-router-dom';
import PPC1 from '../../assets/images/solutions/PPC1.webp';
import PPC2 from '../../assets/images/solutions/PPC2.webp';
import PPC3 from '../../assets/images/solutions/PPC3.webp';
import PPC4 from '../../assets/images/solutions/PPC4.webp';
import Development1 from '../../assets/images/solutions/Development1.webp';
import Development2 from '../../assets/images/solutions/Development2.webp';
import Development3 from '../../assets/images/solutions/Development3.webp';
import Development4 from '../../assets/images/solutions/Development4.webp';
import Branding1 from '../../assets/images/solutions/BlenderMobile.webp';
import Branding2 from '../../assets/images/solutions/BrandPadMobile.webp';
import Branding3 from '../../assets/images/solutions/FigmaMobile.webp';
import Branding4 from '../../assets/images/solutions/UnrealMobile.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import Footer from '../navigation/Footer';
import SolutionHeroSection from './section/SolutionHeroSection';


const sections = [
  {
    title: "Pay Per Click Marketing",
    description: "Feeling stuck with ad campaigns that don’t deliver? Our experienced PPC team is here to elevate your brand’s visibility and drive real results. We connect your business with the right audience on Google, Facebook, and beyond, using targeted strategies that maximize every ad dollar. Through advanced keyword research, A/B testing, and ongoing optimization, we focus on delivering quality clicks that convert. Partner with us to streamline your paid advertising, outpace the competition, and achieve impactful growth!",
    items: [
      [
        { name: "Search Engine Marketing", url: "/solutions/seo" },
        { name: "Social Media Marketing", url: "/sem" }
      ],
      [
        { name: "Marketplace Ads", url: "/" },
        { name: "Display Ads", url: "/" }
      ]
    ],
    images: [PPC1, PPC2, PPC3, PPC4]
  },
  {
    title: "Development",
    description: "Is your website holding back your business? Our development team specializes in creating high-performance, user-friendly websites that bring your brand to life. We go beyond design, building fast, intuitive sites that guide visitors seamlessly from interest to action. With responsive layouts, optimized load times, and a focus on user experience, we ensure your website makes a powerful first impression and keeps customers coming back. Let’s transform your online presence and set a strong foundation for long-term growth.",
    items: [
      [
        { name: "Web Development", url: "/" },
        { name: "E-commerce Development", url: "/" }
      ],
      [
        { name: "Conversion Rate Optimization", url: "/" },
        { name: "Internet of Things (IoT) Development", url: "/" }
      ]
    ],
    images: [Development1, Development2, Development3, Development4]
  },
  {
    title: " Branding & Creative Solutions",
    description: "7.  Branding & Creative Solutions Is your brand making a lasting impression? Our creative team excels in building powerful brand identities that resonate with your audience. We go beyond just logos and designs—crafting cohesive branding that tells your story and captures your market's attention. From eye-catching banners and impactful flyers to in-depth market research, we ensure your brand not only stands out but stays top of mind. Whether you're launching a new venture or redefining an existing brand, we’re here to elevate your visual presence and build a lasting connection with your customers. Let’s bring your vision to life and leave a mark that matters.",
    items: [
      [
        { name: " Logo Designing", url: "/" },
        { name: "Banners & Flyers Designing", url: "/" }
      ],
      [
        { name: "Branding", url: "/content-marketing" },
        { name: "Market research/IoT", url: "/" }
      ]
    ],
    images: [Branding1, Branding2, Branding3, Branding4]
  },
];

const Solutions = () => {
  return (
    <>
      <SolutionHeroSection />
      <div className="w-full">
        <div className="container mx-auto px-[1rem] lg:px-[4rem] lg:my-16 my-8 overflow-hidden">
          {sections.map((section, sectionIndex) => (
            <div key={sectionIndex} className="border-t-2 border-gray-300 pt-8 mt-16">
              <h2 className="mb-4">{section.title}</h2>
              <p>{section.description}</p>

              <div className="grid grid-cols-1 md:grid-cols-2 mt-8 items-center">
                {section.items.map((col, colIndex) => (
                  <div key={colIndex}>
                    <ul className="font-semibold">
                      {col.map((item, itemIndex) => (
                        <li key={itemIndex}>
                          <Link to={item.url} className="text-[24px]">
                            <FontAwesomeIcon icon={faCheckCircle} className="text-orange-400 text-[24px] mr-[10px]" />
                            {item.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>

              <div className="grid grid-cols-1 md:grid-cols-4 gap-10 mt-16 img-solution-shadow pb-8">
                {section.images.map((image, imgIndex) => (
                  <motion.img
                    key={imgIndex}
                    src={image}
                    alt=""
                    className="rounded-lg"
                    whileHover={{ scale: 1.1 }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default Solutions;
