import axios from 'axios';

const API_BASE_URL = 'https://admin.ad2box.com/api';
const IMAGE_BASE_URL = 'https://admin.ad2box.com/public/images'; // Updated image base URL

// Simulate delay for API response (optional)
const simulateDelay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

// Fetch data from API endpoint
export const fetchData = async (endpoint) => {
  try {
    await simulateDelay(1000); // Optional delay

    const response = await axios.get(`${API_BASE_URL}/${endpoint}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

// Get full image URL based on the image path
export const getImageUrl = (imagePath) => `${IMAGE_BASE_URL}/${imagePath}`;
