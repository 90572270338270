import React from 'react'
import { motion } from 'framer-motion';
import Client1 from '../../../assets/images/client/AHS Premier.png';
import Client2 from '../../../assets/images/client/Artisaire.png';
import Client3 from '../../../assets/images/client/Backk Market.png';
import Client4 from '../../../assets/images/client/Bringts.png';
import Client5 from '../../../assets/images/client/Ed-Plant-Shop.png';
import Client6 from '../../../assets/images/client/Formuland.png';
import Client7 from '../../../assets/images/client/Goodfair.png';
import Client8 from '../../../assets/images/client/Guniaa.png';
import Client9 from '../../../assets/images/client/Heim Joints.png';
import Client10 from '../../../assets/images/client/Inkarto.png';
import Client11 from '../../../assets/images/client/Kawaii Kart.png';
import Client12 from '../../../assets/images/client/Made.png';
import Client13 from '../../../assets/images/client/Red-Diamond-Furniture.png';
import Client14 from '../../../assets/images/client/Royals Essence.png';
import Client15 from '../../../assets/images/client/Saadis Cluster.png';
import Client16 from '../../../assets/images/client/Sensei.png';
import Client17 from '../../../assets/images/client/Splendour Sofa.png';
import Client18 from '../../../assets/images/client/Taskade.png';
import Client19 from '../../../assets/images/client/The Attractive Man.png';

const ClientLogo = () => {
    return (
        <>
            <motion.div className="overflow-hidden my-8">
                <motion.div
                    className="flex gap-20 justify-center items-center"
                    animate={{ x: "-100%" }}
                    transition={{ repeat: Infinity, duration: 30, ease: "linear" }}
                >
                    {[...Array(2)].flatMap(() =>
                        [
                            Client1,
                            Client2,
                            Client3,
                            Client4,
                            Client5,
                            Client6,
                            Client7,
                            Client8,
                            Client9,
                            Client10,
                            Client11,
                            Client12,
                            Client13,
                            Client14,
                            Client15,
                            Client16,
                            Client17,
                            Client18,
                            Client19,
                
                        ].map((src, index) => (
                            <div
                                key={`${index}-${Math.random()}`}
                                className="flex-none w-[16.67%] md:w-[16.67%] sm:w-[33.33%] xs:w-[50%]"
                            >
                                <img src={src} alt={`Partner${index + 1}`} className="w-full h-auto bg-" />
                            </div>
                        ))
                    )}
                </motion.div>
            </motion.div>



        </>
    )
}

export default ClientLogo