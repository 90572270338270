import React from 'react'
import PerformanceImg1 from '../../../assets/images/EdsPlantShop.webp';
import PerformanceImg2 from '../../../assets/images/KawaiiKart.webp';
import PerformanceImg3 from '../../../assets/images/TheAttractiveMan.webp';

const BeforeAfterSection = () => {
  return (
   <>
   <div className='grid grid-cols-1 md:grid-cols-3'>
    <div>
    <img src={PerformanceImg1} alt="" />
    </div>
    <div>
    <img src={PerformanceImg2} alt="" />
    </div>
    <div>
    <img src={PerformanceImg3} alt="" />
    </div>

   </div>
   </>
  )
}

export default BeforeAfterSection