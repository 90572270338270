import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Typewriter } from 'react-simple-typewriter'; // Add this library

const items = [
  "Leading Brands",
  "Industry Leaders",
  "Successful Startups",
  "Advertising Professionals",
  "Growing Businesses"
];

const TrustedBySection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const indexInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, 4000); // Change text every 4 seconds to accommodate typing
    return () => clearInterval(indexInterval);
  }, []);

  return (
    <>
      <h2>Trusted by</h2>
      {/* Text animation */}
      <div className="relative overflow-hidden h-[5rem] text-lg font-semibold">
        <motion.div
          key={currentIndex}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
          }}
          style={{ fontSize: '40px', marginTop: '10px' }} 
        >
          <Typewriter
            words={[items[currentIndex]]}
            loop={false}
            cursor
            cursorStyle="|"
            typeSpeed={70}
            deleteSpeed={50}
            delaySpeed={1000}
          />
        </motion.div>
      </div>
    </>
  );
};

export default TrustedBySection;
