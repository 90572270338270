import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import contactData from '../../assets/images/contact_animation.json'
import Lottie from 'lottie-react';

const Contact = () => {
  return (
    <>
      <div className="w-full relative">
        <div className="container mx-auto px-4 lg:px-16 my-8 lg:my-24 overflow-hidden">
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div>
              <h1 className="leading-tight">We've got a great feeling about this</h1>
              <button
                className="mt-6 text-[17px] bg-gray-950 text-white px-6 py-2 rounded-full transition duration-300 ease-in-out transform hover:bg-orange-500 hover:-translate-y-1 flex items-center"
              >
                Let's Get Started
                <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
              </button>
            </div>
            <div>
              <Lottie animationData={contactData} loop={true} className="w-full max-w-[500px] float-right" />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-4 mt-8 gap-16">
            <div>
              <h4 className="mb-2">Speak to us</h4>
              <p><a href="tel:+918810734094">+91 8810 734 094</a></p>
              <p><a href="mailto:hello@ad2box.com">hello@ad2box.com</a></p>
            </div>
            <div>
              <h4 className="mb-2">India</h4>
              <p>14A, Vibhuti Khand, Gomti Nagar, Lucknow, UP, India 22601</p>
            </div>
            <div>
              <h4 className="mb-2">USA</h4>
              <p>2443 Sierra Nevada Road, Mammoth Lakes CA, USA. 93546</p>
            </div>
            <div>
              <h4 className="mb-2">Australia</h4>
              <p>73 Warren Avenue, Killingworth, New South Wales (NSW), AUS. 2278</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
