import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchData } from '../../services/apiService';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion, faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import Case1 from '../../assets/images/Digital-Agency-Portfolio-Work-Image-1.webp';
import Case2 from '../../assets/images/Digital-Agency-Portfolio-Work-Image-2-1536x923.webp';
import Case3 from '../../assets/images/Digital-Agency-Portfolio-Work-Image-3.webp';
import Case4 from '../../assets/images/Digital-Agency-Portfolio-Work-Image-4.webp';
import Case5 from '../../assets/images/Digital-Agency-Portfolio-Work-Image-5.webp';
import Footer from '../navigation/Footer'; 


const SingleCaseStudy = () => {
  const images = [
    'https://res.cloudinary.com/dannpkzcu/image/upload/v1727549648/nt0eNqt8P9k-1024x576_ycd6op.jpg',
    'https://res.cloudinary.com/dannpkzcu/image/upload/v1727549648/OBV5XREI-ig-1024x683_dzms4h.jpg',
    'https://res.cloudinary.com/dannpkzcu/image/upload/v1727549648/pexels-kubrakuzu-20068318-683x1024_pury4h.jpg',
    'https://res.cloudinary.com/dannpkzcu/image/upload/v1727549649/8IRUwJkbwEA-819x1024_uet1dh.jpg',
    'https://res.cloudinary.com/dannpkzcu/image/upload/v1727549648/OBV5XREI-ig-1024x683_dzms4h.jpg',
  ];
  return (
    <>
      <div className='w-full relative bg-gradient'>
        <div className='container mx-auto px-[1rem] lg:px-[2rem] pt-56 pb-16  overflow-hidden'>
          <div className='grid grid-cols-1 md:grid-cols-8'>
            <div className='col-span-6'>
              <h1 className='mb-4 md:mb-8'>The Which Wave</h1>
              <p>
                Each project encapsulates a unique narrative, showcasing our proficiency in crafting tailored solutions
                across various industries. From pioneering tech solutions to dynamic digital strategies, our portfolio
                is a living canvas that reflects the convergence of creativity, technology, and strategic thinking
              </p>
            </div>
          </div>
        </div>
      </div>
      <Swiper
        slidesPerView={2} // Default to 2 slides per view
        breakpoints={{
          640: { slidesPerView: 2 }, // 2 slides for small screens
          768: { slidesPerView: 3 }, // 3 slides for medium screens
          1024: { slidesPerView: 5 }, // 5 slides for large screens
        }}
        style={{ height: 'auto' }}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index} style={{ width: index < 2 ? '700px' : 'auto' }}>
            <img
              src={image}
              alt={`Slide ${index}`}
              style={{ width: '100%', height: '251px', objectFit: 'cover', borderRadius: '8px' }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className='w-full relative bg-slate-200'>
        <div className='container mx-auto px-[1rem] lg:px-[2rem] lg:pt-24 md:pb-32 py-8 overflow-hidden'>
          <div className='grid grid-cols-1 md:grid-cols-8 gap-4'>
            <div className='col-span-3'>
              <h2>TECHGROWTH</h2>
            </div>
            <div className='col-span-5'>
              <p>Embark on a transformative journey as we unveil the success story of our collaboration with
                TechGrowth on the groundbreaking project Digital Revolution. Witness firsthand how Outgrid
                revolutionized their digital landscape to propel them into a new era of growth and innovation.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full case-studies-bg h-screen relative">
        <div className="container mx-auto px-4 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-8">
            <div className="col-span-5 md:col-start-4">
              <div className="bg-white flex justify-between py-8 rounded-lg shadow-lg relative top-[-5rem] z-10">
                <div className="border-r-2 border-gray-200 px-16 text-center">
                  <h4 className="text-orange-400 text-5xl font-bold mb-4">50</h4>
                  <span className="text-lg">Countries</span>
                </div>
                <div className="border-r-2 border-gray-200 px-16 text-center">
                  <h4 className="text-orange-400 text-5xl font-bold mb-4">100+</h4>
                  <span className="text-lg">Projects</span>
                </div>
                <div className="px-16 text-center">
                  <h4 className="text-orange-400 text-5xl font-bold mb-4">30+</h4>
                  <span className="text-lg">Partners</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full bg-gray-100">
        <div className='container mx-auto px-[1rem] lg:px-[2rem] lg:pt-24 md:pb-32 py-8 overflow-hidden'>
          <div className='grid grid-cols-1 md:grid-cols-8 gap-4'>
            <div className='col-span-3'>
              <h2>CHALLENGE</h2>
            </div>
            <div className='col-span-5'>
              <p>
                In the initial chapter of our client’s transformative journey, we delved into a myriad of challenges
                that demanded our strategic prowess. Here, we unravel the specific hurdles that stood in the way,
                creating a narrative of obstacles that awaited .
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 bg-white mt-16 p-8 rounded-lg">
            <div className="hover:bg-orange-400 p-8 rounded-lg group">
              <h4 className="text-orange-400 group-hover:text-white">01</h4>
              <h3 className="leading-10 my-4 group-hover:text-white">Digital Visibility and Engagement</h3>
              <p className="group-hover:text-white">
                The client grappled with a significant deficit in online visibility, impacting customer engagement
                and brand recognition. This challenge underscored the critical need for a robust digital strategy to
                enhance their digital footprint.
              </p>
            </div>
            <div className="hover:bg-orange-400 p-8 rounded-lg group">
              <h4 className="text-orange-400 group-hover:text-white">02</h4>
              <h3 className="leading-10 my-4 group-hover:text-white">Digital Visibility and Engagement</h3>
              <p className="group-hover:text-white">
                The client grappled with a significant deficit in online visibility, impacting customer engagement
                and brand recognition. This challenge underscored the critical need for a robust digital strategy to
                enhance their digital footprint.
              </p>
            </div>
            <div className="hover:bg-orange-400 p-8 rounded-lg group">
              <h4 className="text-orange-400 group-hover:text-white">03</h4>
              <h3 className="leading-10 my-4 group-hover:text-white">Digital Visibility and Engagement</h3>
              <p className="group-hover:text-white">
                The client grappled with a significant deficit in online visibility, impacting customer engagement
                and brand recognition. This challenge underscored the critical need for a robust digital strategy to
                enhance their digital footprint.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full relative'>
        <div className='container mx-auto px-[1rem] lg:px-[2rem] lg:pt-24 md:pb-32 py-8 overflow-hidden'>
          <div className='grid grid-cols-1 md:grid-cols-8 gap-4'>
            <div className='col-span-3'>
              <h2>SOLUTION</h2>
            </div>
            <div className='col-span-5'>
              <p>
                Having navigated the challenges, the second chapter of our client’s journey outlines the strategic
                solutions and innovative methodologies we employed to overcome obstacles. Here, we illuminate the
                transformative steps taken to turn challenges into opportunities.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 bg-white mt-16 rounded-lg items-start">
            <div className="hover:bg-orange-400 p-8 rounded-lg group bg-gray-100">
              <span className="text-orange-400"><FontAwesomeIcon icon={faQuestion} size="2x" className='bg-white py-4 px-6 rounded-md' /></span>
              <h3 className="leading-10 my-4 group-hover:text-white">Digital Visibility and Engagement</h3>
              <p className="group-hover:text-white">
                The client grappled with a significant deficit in online visibility, impacting customer engagement
                and brand recognition. This challenge underscored the critical need for a robust digital strategy to
                enhance their digital footprint.
              </p>
            </div>
            <div className="hover:bg-orange-400 p-8 rounded-lg group bg-gray-100 mt-16">
              <span className="text-orange-400"><FontAwesomeIcon icon={faQuestion} size="2x" className='bg-white py-4 px-6 rounded-md' /></span>
              <h3 className="leading-10 my-4 group-hover:text-white">Digital Visibility and Engagement</h3>
              <p className="group-hover:text-white">
                The client grappled with a significant deficit in online visibility, impacting customer engagement
                and brand recognition. This challenge underscored the critical need for a robust digital strategy to
                enhance their digital footprint.
              </p>
            </div>
            <div className="hover:bg-orange-400 p-8 rounded-lg group bg-gray-100 mt-32">
              <span className="text-orange-400"><FontAwesomeIcon icon={faQuestion} size="2x" className='bg-white py-4 px-6 rounded-md' /></span>
              <h3 className="leading-10 my-4 group-hover:text-white">Digital Visibility and Engagement</h3>
              <p className="group-hover:text-white">
                The client grappled with a significant deficit in online visibility, impacting customer engagement
                and brand recognition. This challenge underscored the critical need for a robust digital strategy to
                enhance their digital footprint.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full relative'>
        <div className='px-[1rem] lg:px-[4rem] lg:pt-24 md:pb-32 py-8 overflow-hidden bg-slate-100 rounded-[3rem]'>
          <div className='text-center'>
            <h2>Our journey epitomized innovation collaborative sessions, dynamic content, and emerging
              tech paved the way for the client's sustained success.</h2>
          </div>
        </div>
      </div>
      <div className='w-full relative'>
        <div className='container mx-auto px-[1rem] lg:px-[2rem] lg:pt-24 md:pb-32 py-8 overflow-hidden'>
          <h2>OUR SIMPLE PROCESS</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 bg-white mt-16 rounded-lg items-start">
            <div className="hover:bg-orange-400 p-8 rounded-lg group bg-gray-100">
              <h4 className="text-orange-400 group-hover:text-white">Week 1</h4>
              <h3 className="leading-10 my-4 group-hover:text-white">Digital Visibility and Engagement</h3>
              <p className="group-hover:text-white">
                The client grappled with a significant deficit in online visibility, impacting customer engagement
                and brand recognition. This challenge underscored the critical need for a robust digital strategy to
                enhance their digital footprint.
              </p>
            </div>
            <div className="hover:bg-orange-400 p-8 rounded-lg group bg-gray-100 ">
              <h4 className="text-orange-400 group-hover:text-white">Week 2</h4>
              <h3 className="leading-10 my-4 group-hover:text-white">Digital Visibility and Engagement</h3>
              <p className="group-hover:text-white">
                The client grappled with a significant deficit in online visibility, impacting customer engagement
                and brand recognition. This challenge underscored the critical need for a robust digital strategy to
                enhance their digital footprint.
              </p>
            </div>
            <div className="hover:bg-orange-400 p-8 rounded-lg group bg-gray-100">
              <h4 className="text-orange-400 group-hover:text-white">Week 3</h4>
              <h3 className="leading-10 my-4 group-hover:text-white">Digital Visibility and Engagement</h3>
              <p className="group-hover:text-white">
                The client grappled with a significant deficit in online visibility, impacting customer engagement
                and brand recognition. This challenge underscored the critical need for a robust digital strategy to
                enhance their digital footprint.
              </p>
            </div>
          </div>
          <div className="flex justify-around items-center mt-8">
            <div className="flex flex-col items-center">
              <div className="w-4 h-4 bg-gray-300 rounded-full"></div>
              <div className="w-1 h-32 bg-gray-300"></div>
            </div>

            <div className="flex flex-col items-center">
              <div className="w-4 h-4 bg-gray-300  rounded-full"></div>
              <div className="w-1 h-32 bg-gray-300 "></div>
            </div>

            <div className="flex flex-col items-center">
              <div className="w-4 h-4 bg-gray-300  rounded-full"></div>
              <div className="w-1 h-32 bg-gray-300 "></div>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full relative'>
        <div className='container mx-auto px-[1rem] lg:px-[2rem] lg:pt-24 md:pb-32 py-8 overflow-hidden'>
          <div className="grid grid-cols-1 md:grid-cols-5">
            <div className='col-span-1'></div>
            <div className='col-span-3 text-center'>
              <h2>Impact of the work performed</h2>
              <p>Embark on a journey where every action is a catalyst for profound change, as our strategic
                approach unfolds to create a lasting impact on tasks, projects, and beyond.
              </p>
            </div>
            <div className='col-span-1'></div>
          </div>
          <div className='flex justify-around items-center mt-8'>
            <div className="flex flex-col items-center ">
              <h2 className='mb-2'>3.9</h2>
              <span className='text-[16px] font-semibold'>Visitors in two weeks</span>
            </div>
            <div className="flex flex-col items-center border-r-2 border-l-2 border-gray-200 px-24">
              <h2 className='mb-2'>3.9</h2>
              <span className='text-[16px] font-semibold'>Visitors in two weeks</span>
            </div>
            <div className="flex flex-col items-center">
              <h2 className='mb-2'>3.9</h2>
              <span className='text-[16px] font-semibold'>Visitors in two weeks</span>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full relative'>
        <div className='container mx-auto px-[1rem] lg:px-[2rem] lg:pt-24 md:pb-32 py-8 overflow-hidden'>
          <div className="flex gap-6">
            <div className='col-span-2'>
              <img src={Case1} alt="" className='rounded-3xl h-full' />
            </div>
            <div className='col-span-3'>
              <img src={Case2} alt="" className='rounded-3xl h-full' />
            </div>
          </div>
          <div className='flex gap-6 mt-6'>
            <div>
              <img src={Case3} alt="" className='rounded-3xl h-full' />
            </div>
            <div>
              <img src={Case4} alt="" className='rounded-3xl h-full' />
            </div>
            <div>
              <img src={Case5} alt="" className='rounded-3xl h-full' />
            </div>
          </div>
        </div>
      </div>
      <div className='w-full relative case-bg '>
        <div className='container mx-auto px-[1rem] lg:px-[2rem] lg:pt-24 md:pb-32 py-8 overflow-hidden text-white'>
        <FontAwesomeIcon icon={faQuoteLeft} size='4x'className='mb-4'/>
          <p className='text-[40px] font-semibold'>Collaborating with Digiquest was a game-changer for Tech Grow. Their innovative 
            solutions not only addressed our challenges but elevated our online presence to new heights. 
            The results speak for themselves. Our users are delighted, and we’ve gained a new level of trust 
            in the digital space.”
          </p>
          <FontAwesomeIcon icon={faQuoteRight} size='4x' className='float-end mt-4	'/>
          <h3 className='mt-16'>Rendy Samuel</h3>
          <h4>CEO at Tech Grow.</h4>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default SingleCaseStudy;
