import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { motion } from 'framer-motion';
import emailjs from 'emailjs-com';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '../pages/section/Button';

const PopupForm = ({ isOpen, togglePopup }) => {
  const [formData, setFormData] = useState({
    firstName: "", lastName: "", email: "", phone: "", location: "",
    organization: "", budget: "", service: "", summary: ""
  });

  const formFields = [
    { id: "firstName", name: "firstName", label: "Enter Your First Name", type: "text", placeholder: "First Name" },
    { id: "lastName", name: "lastName", label: "Enter Your Last Name", type: "text", placeholder: "Last Name" },
    { id: "email", name: "email", label: "Enter Your Email", type: "email", placeholder: "Email" },
    { id: "phone", name: "phone", label: "Enter Your Phone No.", type: "tel", placeholder: "Phone" },
    { id: "location", name: "location", label: "Enter Your Location", type: "text", placeholder: "Location" },
    { id: "organization", name: "organization", label: "Name Of Organization", type: "text", placeholder: "Organization" },
    { id: "budget", name: "budget", label: "Select Your Approximate Budget", type: "select", placeholder: "Select one..." },
    { id: "service", name: "service", label: "Select Your Services", type: "select", placeholder: "Select one..." },
    { id: "summary", name: "summary", label: "Summary of Your Project", type: "textarea", placeholder: "Tell us about your project" }
  ];

  const handleChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();
    const { firstName, email, lastName, phone, location, organization, budget, service, summary } = formData;
    if (!firstName || !email || !lastName || !phone || !location || !organization || !budget || !service || !summary) {
      toast.error("Please fill in all required fields.");
      return;
    }
    emailjs.send("service_cu8hs0v", "template_a67q09h", formData, "0TEAYV1U90jtSc3Mx")
      .then(() => {
        toast.success("Your Form sent successfully!");
        setFormData({ firstName: "", lastName: "", email: "", phone: "", location: "", organization: "", budget: "", service: "", summary: "" });
      }, () => {
        toast.error("Failed to send email. Please try again.");
      });
  };

  const popupVariants = { hidden: { opacity: 0, y: 50 }, visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }, exit: { opacity: 0, y: 50, transition: { duration: 0.5 } } };

  // Hide scrollbar when popup is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto'; // Reset on cleanup
    };
  }, [isOpen]);

  return isOpen ? (
    <>
      <motion.div initial="hidden" animate="visible" exit="exit" variants={popupVariants}
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-[#F5F4F0] p-6 rounded-3xl shadow-lg relative w-5/6 max-h-[80vh] overflow-y-auto">
          <button onClick={togglePopup} aria-label="Close popup" className="absolute top-4 right-4 text-gray-500"><FaTimes size={24} /></button>
          <div className="px-0 lg:px-[10rem] text-center">
            <h2 className='text-orange-500 font-medium lg:mb-8 mb-4 lg:text-[60px] text-[28px]'>Let's get started</h2>
            <h4>Fill in the blanks and we'll respond in one business day.</h4>
            <form onSubmit={handleSubmit}>
              <div className="mt-10 grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-2">
                {formFields.map(({ id, name, label, type, placeholder }) => (
                  <div key={id} className={`md:col-span-${type === 'textarea' ? 2 : 1}`}>
                    <label htmlFor={id} className="block text-left font-medium leading-6 text-gray-900">{label}</label>
                    <div className="mt-2">
                      {type === "textarea" ? (
                        <textarea id={id} name={name} rows={4} placeholder={placeholder}
                          className="block w-full border-0 border-b border-gray-400 bg-transparent py-1.5 text-gray-900 overflow-hidden"
                          value={formData[name]} onChange={handleChange} />
                      ) : type === "select" ? (
                        <select id={id} name={name}
                          className="block w-full border-0 border-b border-gray-400 bg-transparent py-1.5 text-gray-900"
                          value={formData[name]} onChange={handleChange}>
                          <option value="">{placeholder}</option>
                          {id === "budget" && <>
                            <option value="below_10k">Below $10k</option>
                            <option value="10k_25k">$10k - $25k</option>
                            <option value="25k_50k">$25k - $50k</option>
                            <option value="50k_75k">$50k - $75k</option>
                            <option value="75k_100k">$75k - $100k</option>
                            <option value="100k_plus">$100k+</option>
                          </>}
                          {id === "service" && <>
                            <option value="seo">Search Engine Optimization</option>
                            <option value="sem">Search Engine Marketing</option>
                            <option value="smm">Social Media Marketing</option>
                            <option value="email_marketing">Email Marketing</option>
                            <option value="content_marketing">Content Marketing</option>
                          </>}
                        </select>
                      ) : (
                        <input id={id} name={name} type={type} placeholder={placeholder}
                          className="block w-full border-0 border-b border-gray-400 bg-transparent py-1.5 text-gray-900"
                          value={formData[name]} onChange={handleChange} />
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-8 flex justify-center col-span-1 text-black">
              <Button label="Submit" link="#" onClick={handleSubmit} padding={{ top: 5, right: 20, bottom: 5, left: 20 }} />
              </div>
            </form>
          </div>
        </div>
      </motion.div>
      <ToastContainer />
    </>
  ) : null;
};

export default PopupForm;
