import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchData } from '../../services/apiService';
import Footer from '../navigation/Footer';

const IMAGE_BASE_URL = 'https://admin.ad2box.com/public/images';
const getImageUrl = (imagePath) => `${IMAGE_BASE_URL}/${imagePath}`;

const SinglePost = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const data = await fetchData(`posts/${id}`);
        setPost(data);
      } catch (err) {
        setError('Error fetching post');
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [id]);

  const truncateContent = (content, wordLimit) => {
    const words = content.split(' ');
    return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '...' : content;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  // Show loading spinner or placeholder until data is fetched
  if (loading) return <div className="text-center my-24">Loading...</div>;

  // Display error message if there's an error in data fetching
  if (error) return <div className="text-center my-24">{error}</div>;

  return (
    <>
      <div className='w-full relative'>
        <div className="container mx-auto px-4 py-24">
          <div className='grid grid-cols-1 md:grid-cols-12'>
            <div className='col-span-2'></div>
            <div className='col-span-8'>
              <h1 className="text-3xl font-bold mb-4">{post.title}</h1>
              <p>
                {truncateContent(post.content, 30)}
              </p>
              <p className='my-4'>
                <b><span>{formatDate(post.created_at)}</span></b> <span> | </span><span>by RajSiddhartha</span>
              </p>
              <img
                src={getImageUrl(post.image)}
                alt={post.title}
                className="w-full rounded-md shadow-md my-16"
              />
              <p>{post.content}</p>
            </div>
            <div className='col-span-2'></div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SinglePost;
