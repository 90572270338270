import React from 'react';
import { motion } from 'framer-motion';

const SolutionCard = ({ title, description, items }) => {
  return (
    <div>
      <h3 className='leading-tight md:text-[35px]'>{title}</h3>
      <p className='my-4'>{description}</p>
      {items.map((item, index) => (
        <div key={index} className='relative overflow-hidden mb-4'>
          <p className='text-[16px] border-b-2 border-gray-300 pb-2'>
            {item}
          </p>
          <motion.div
            className="absolute bottom-0 left-0 h-[2px] bg-black"
            initial={{ width: '0%' }}
            animate={{ width: '100%' }}
            transition={{ 
              duration: 10, 
              delay: index * 0.5, 
              repeat: Infinity, 
              repeatType: 'reverse',
              ease: 'easeInOut' 
            }}
          />
        </div>
      ))}
    </div>
  );
};

const Solutions = () => {
  const solutionsData = [
    {
      title: 'Pay-Per-Click (PPC) Advertising',
      description:
        'Reach the right audience at the right time. Our targeted PPC strategies ensure that your marketing budget delivers maximum ROI with meaningful results.',
      items: [
        'Keyword Research & Bid Management',
        'A/B Testing & Performance Analysis',
        'Conversion Rate Optimization',
      ],
    },
    {
      title: 'Development & Automation',
      description:
        'Streamline your business operations with smart development solutions. We create custom tools and automated workflows that enhance efficiency and drive growth.',
      items: [
        'API Integrations & Custom Software',
        'Process Automation & Workflows',
        'Scalable Solutions Tailored to Your Needs',
      ],
    },
    {
      title: ' Analytics & Insights',
      description:
        'Data is the backbone of effective marketing. Our data-driven approach helps you understand your audience and optimize your strategies for sustainable growth.',
      items: [
        'Advanced Data Tracking & Reporting',
        'Customer Behavior Analysis',
        'Actionable Insights & Recommendations',
      ],
    },
  ];

  return (
    <>
      <div className='container mx-auto px-[1rem] lg:px-[4rem] lg:py-28 py-8 overflow-hidden'>
        <div className='grid grid-cols-1 md:grid-cols-5 gap-4'>
          <div className='col-span-1'></div>
          <div className='col-span-3 text-center'>
            <span className='text-[1rem] border-2 border-black rounded-full px-5 py-1 font-semibold'>
              Our Solutions
            </span>
            <h2 className='mt-6'>Elevate your brand with our tailored solutions</h2>
          </div>
          <div className='col-span-1'></div>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-x-20 mt-8'>
          {solutionsData.map((solution, index) => (
            <SolutionCard
              key={index}
              title={solution.title}
              description={solution.description}
              items={solution.items}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Solutions;
